<template>
	<div class="auth-wrapper auth-v1 px-2">
		<div class="auth-inner py-2">
			<!-- set Password v1 -->
			<b-card class="mb-0">
				<!-- logo -->
				<b-link class="brand-logo" :to="{ name: 'auth-login-v1' }">
					<vuexy-logo />

					<h2 class="brand-text text-primary ml-1">Emoty</h2>
				</b-link>

				<b-card-title class="mb-1"> Create Password 🔒 </b-card-title>
				<b-card-text class="mb-2">
					Your new password must be different from previously used passwords
				</b-card-text>

				<!-- form -->
				<validation-observer ref="simpleRules">
					<b-form
						method="POST"
						class="auth-set-password-form mt-2"
						@submit.prevent="validationForm"
					>
						<b-form-group label="Email" label-for="set-email">
							<validation-provider
								#default="{ errors }"
								name="email"
								vid="email"
								rules="required|email"
							>
								<b-input-group class="input-group-merge">
									<b-form-input
										id="set-email-new"
										v-model="userEmail"
										class="form-control-merge"
										name="set-email-new"
										placeholder="email"
									/>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
						<!-- password -->
						<b-form-group label="New Password" label-for="set-password-new">
							<validation-provider
								#default="{ errors }"
								name="Password"
								vid="Password"
							>
								<b-input-group
									class="input-group-merge"
									:class="errors.length > 0 ? 'is-invalid' : null"
								>
									<b-form-input
										id="set-password-new"
										v-model="password"
										:type="password1FieldType"
										:state="errors.length > 0 ? false : null"
										class="form-control-merge"
										name="set-password-new"
										placeholder="············"
									/>
									<b-input-group-append is-text>
										<feather-icon
											class="cursor-pointer"
											:icon="password1ToggleIcon"
											@click="togglePassword1Visibility"
										/>
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<!-- confirm password -->
						<b-form-group
							label-for="set-password-confirm"
							label="Confirm Password"
						>
							<validation-provider
								#default="{ errors }"
								name="Confirm Password"
								rules="required|confirmed:Password"
							>
								<b-input-group
									class="input-group-merge"
									:class="errors.length > 0 ? 'is-invalid' : null"
								>
									<b-form-input
										id="set-password-confirm"
										v-model="cPassword"
										:type="password2FieldType"
										class="form-control-merge"
										:state="errors.length > 0 ? false : null"
										name="set-password-confirm"
										placeholder="············"
									/>
									<b-input-group-append is-text>
										<feather-icon
											class="cursor-pointer"
											:icon="password2ToggleIcon"
											@click="togglePassword2Visibility"
										/>
									</b-input-group-append>
								</b-input-group>
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>

						<!-- submit button -->
						<b-button block type="submit" variant="primary">
							Set New Password
						</b-button>
					</b-form>
				</validation-observer>

				<p class="text-center mt-2">
					<b-link :to="{ name: 'auth-login-v1' }">
						<feather-icon icon="ChevronLeftIcon" /> Back to login
					</b-link>
				</p>
			</b-card>
			<!-- /set Password v1 -->
		</div>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
// eslint-disable-next-line import/no-extraneous-dependencies
import VuexyLogo from '@core/layouts/components/Logo.vue';
import {
	BCard,
	BCardTitle,
	BCardText,
	BForm,
	BFormGroup,
	BInputGroup,
	BInputGroupAppend,
	BLink,
	BFormInput,
	BButton,
} from 'bootstrap-vue';
// eslint-disable-next-line import/no-extraneous-dependencies
import { required } from '@validations';
import { mapActions } from 'vuex';

export default {
	components: {
		VuexyLogo,
		BCard,
		BButton,
		BCardTitle,
		BCardText,
		BForm,
		BFormGroup,
		BInputGroup,
		BLink,
		BFormInput,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
	},
	data() {
		return {
			userEmail: '',
			cPassword: '',
			password: '',
			// validation
			required,

			// Toggle Password
			password1FieldType: 'password',
			password2FieldType: 'password',
		};
	},
	computed: {
		password1ToggleIcon() {
			return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
		},
		password2ToggleIcon() {
			return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
		},
	},
	methods: {
		...mapActions('userManagement', ['setUserPassword']),
		togglePassword1Visibility() {
			// eslint-disable-next-line operator-linebreak
			this.password1FieldType =
				this.password1FieldType === 'password' ? 'text' : 'password';
		},
		togglePassword2Visibility() {
			// eslint-disable-next-line operator-linebreak
			this.password2FieldType =
				this.password2FieldType === 'password' ? 'text' : 'password';
		},
		validationForm() {
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					this.setUserPassword({
						email: this.userEmail,
						password: this.password,
						repeatedPassword: this.cPassword,
					}).then((response) => {
						if (!response.data.error) {
							this.$router.push('/login');
						}
					});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
